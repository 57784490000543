import React, { useState } from "react";

import { HashContext } from "./hash.context";

export const HashProdvider = ({ children }) => {
  const [hashId, setHashId] = useState("1");
  const _setHashId = (id) => {
    setHashId(id);
  };
  return (
    <HashContext.Provider
      value={{
        hashId,
        _setHashId,
      }}
    >
      {children}
    </HashContext.Provider>
  );
};
