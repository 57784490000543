import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const FroalaEdit = ({ type, onChange, value, defaultValue, ...rest }) => {
  return type=="share" ? (
    <SunEditor
      setDefaultStyle="font-family: Open Sans; font-size: 14px;"
      disable={true}
      height="80vh"
      {...rest}
      onChange={onChange}
      value={defaultValue}
      setContents={value}
      defaultValue={defaultValue}
    />
  ) : (
    <SunEditor
    name={Math.random()}
      setOptions={editorOptions}
      setDefaultStyle="font-family: Open Sans; font-size: 14px;"
      height="300"
      placeholder="Please type here..."
      {...rest}
      onChange={onChange}
      setContents={value}
      autoFocus={false}
      // defaultValue={defaultValue}
      // value={value}
    />
  );
};
export default FroalaEdit;
let editorOptions = {
  height: 200,
  value: "",

  mode: "classic",
  toolbarContainer: "#custom_toolbar",
  katex: "window.katex",
  charCounter: true,
  charCounterType: "char",
  charCounterLabel: "String",
  minHeight: "200",
  maxHeight: "800",
  videoResizing: false,
  videoHeightShow: false,
  videoFileInput: false,
  videoUrlInput: false,
  videoRatioShow: false,
  tabDisable: false,

  buttonList: [
    [
      "undo",
      "redo",
      "font",
      "fontSize",
      "formatBlock",
      "paragraphStyle",
      "blockquote",
      "bold",
      "underline",
      "italic",
      "strike",
      "subscript",
      "superscript",
      "fontColor",
      "hiliteColor",
      "textStyle",
      "removeFormat",
      "outdent",
      "indent",
      "align",
      "horizontalRule",
      "list",
      "lineHeight",
      "table",
      "link",

      "fullScreen",
      "showBlocks",
      "codeView",
    ],
  ],
};
