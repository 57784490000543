import React, { useState, useContext } from "react";
import { Menu, Button, Modal, Input, message } from "antd";
import { Fetch, Get } from "../utils";
import { SearchContext } from "../context/search/search.context";

const TopicMenu = ({
  topics,
  selectedKey,
  changeSelectedKey,
  _getTopic,
  SubId,
}) => {
  const {
    search,

    Addsearch,
    clearSearch,
  } = useContext(SearchContext);
  const [topic, setTopic] = useState("");
  const [searchTerm, setSearchterm] = useState("");
  const [newHashShow, setNewHashShow] = useState(false);
  const styledTopics = [];
  topics.forEach((topic, index) =>
    styledTopics.push(
      <Menu.Item key={index} onClick={changeSelectedKey}>
        {topic.name}
      </Menu.Item>
    )
  );
  const _onaddTopic = async () => {
    const add = await Fetch("/hash", "post", {
      name: topic,
      sub: SubId,
    });
    if (add?.hash) {
      message.success("New Hash Created Successfully");

      setNewHashShow(false);
      _getTopic();
      setTopic("");
    }
  };

  const onSearch = async (value) => {
    clearSearch();
    const searchData = await Get(
      `/note/search/note?title=${value===''?"null":value}&sub=${SubId}`
    );

    if (searchData) {
      Addsearch(searchData?.note);
    } else {
      Addsearch([]);
    }
  };
  return (
    <>
      <Modal
        title="Add New Hash"
        centered
        visible={newHashShow}
        onCancel={() => {
          setNewHashShow(false);
        }}
        footer={null}
      >
        <Input
          onChange={(e) => {
            setTopic(e.target.value);
          }}
          value={topic}
          className="mb-20"
          placeholder="Enter Your Hash"
        />
        <Button
          onClick={() => {
            _onaddTopic();
          }}
          className="w-full"
          type="primary"
        >
          Add
        </Button>
      </Modal>
      <Menu mode="inline" selectedKeys={[selectedKey]}>
        <Input
          // onChange={(e) => {
          //   setSearchterm(e.target.value);
          // }}
          onChange={(e) => {
            setSearchterm(e.target?.value);
            setTimeout(() => {
              onSearch(e.target?.value);
            }, 100);
          }}
          value={searchTerm}
          className="mb-20 mt-4"
          placeholder="search"
          style={{ border: "1px solid #1890ff" }}
        />
        {styledTopics}
        <Menu.Item>
          <Button
            onClick={() => {
              setNewHashShow(true);
            }}
            type="dashed"
          >
            + New Hash
          </Button>
        </Menu.Item>
      </Menu>
    </>
  );
};
export default TopicMenu;
