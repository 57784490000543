import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { UserContext } from "./user.context";
import { SERVER_URL } from "../../utils";
import { Config } from "../../utils/config";
export const UserAuthProdvider = ({ children, isAuthenticated }) => {
  const [userInfo, setUserInfo] = useState("");

  const { user, getAccessTokenSilently } = useAuth0();

  const getCurrentUser = async () => {
    const userInfo = await localStorage.getItem("user");
    const token = isAuthenticated && (await getAccessTokenSilently());
    if (userInfo) {
      setUserInfo(userInfo);
    } else {
      if (token.length > 0) {
        await fetch(`${Config.server}/auth/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
            sub: user.sub,
            name: user.nickname,
          }),
        })
          .then((r) => r.json())
          .then(async (r) => {

            await localStorage.setItem(
              "user",
              JSON.stringify({
                email: user.email,
                name: user.nickname,
                sub: user.sub,
              })
            );
            await window.location.reload()
            setUserInfo({
              email: user.email,
              name: user.nickname,
              sub: user.sub,
            });
          });
      } else {
        console.log("check");
      }
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        userInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
