import React, { useState } from "react";
import { Row, Col, Button, Input, Modal, notification,message } from "antd";
import ContentEditable from "react-contenteditable";

import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { EditableTagGroup, FroalaEdit } from "../../../components";
import { SHARE_URL } from "../../../utils/server.constant";
import { Fetch, trimSpaces } from "../../../utils";
import { Config } from "../../../utils/config";

const CardEditor = ({ title, onChange, data,onDelete }) => {

  const [share, setShare] = useState({
    show: false,
    link: "",
  });

  const _onupdatetitle = async (id, data) => {
    await Fetch(`/note/updatetitle`, "post", {
      id: id,
      title: data,
    });
  };


  // const _onupdatestatus = async (id) => {
  //   await Fetch(`/note/updatestatus`, "post", {
  //     id: id,
  //     status: "PUBLISH",
  //   });
  //   const args = {
  //     message: "",
  //     description: "Note Published Successfully",
  //     duration: 0,
  //   };
  //   notification.open(args);
  // };
  // const ondelete = async (id) => {
  //   await Fetch(`/note/delete`, "post", {
  //     id: id,
      
  //   });
  //   const args = {
  //     message: "",
  //     description: "Note deleted Successfully",
  //     duration: 0,
  //   };
  //   notification.open(args);
  // };

  return (
    <>
      <Modal
        title="Sahre Note"
        centered
        visible={share.show}
        onCancel={() => {
          setShare({
            show: false,
            link: "",
          });
        }}
        footer={null}
      >
        <Input className="mb-20" value={`${Config.share}${share.link}`} disabled />
        <CopyToClipboard
          text={`${Config.share}${share.link}`}
          onCopy={() => {
            // const args = {
            //   message: "",
            //   description: "Share link copied sucessfully",
            //   duration: 0,
            // };
            message.success("Share link copied sucessfully");
          }}
        >
          <Button className="w-full" type="primary">
            Copy Link
          </Button>
        </CopyToClipboard>
      </Modal>

      <Col span={24}>
        <div className="editor-container">
          <div className="flex-row">
            <div style={{ width: "257px" }}>
              <ContentEditable
                html={
                  data?.title
                    ? trimSpaces(data?.title)
                    : "<p>Type Your Title</p>"
                }
                className="note-title"
                disabled={false}
                onChange={(e) => {
                  _onupdatetitle(data._id, e.target.value);
                }}
              />
            </div>

            <span className="note-title">
              ({moment(data.createdAt).format("LL") + "  " + moment(data.createdAt).format("LTS")})
            </span>
            <div className="mt-10">
              
                <a
                  onClick={onDelete}
                  className="note-title ml-5"
                >
                  Delete
                </a>
              
              <a
                className="note-title ml-5"
                onClick={() => {
                  setShare({
                    show: true,
                    link: data._id,
                  });
                }}
              >
                Share
              </a>
            </div>
          </div>

          <FroalaEdit
            onChange={onChange}
            value={data.content}
            // defaultValue={data.content}
          />
          <div className="mt-10">
            <EditableTagGroup type="NOTE" id={data._id} tag={data?.tag} />
          </div>
        </div>
      </Col>
    </>
  );
};

export default CardEditor;
