import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { FroalaEdit } from "../../components";
import { Get } from "../../utils";

function SahreNote() {
  const location = useLocation();
  const [note, setNote] = useState("");

  const getNote = async () => {
    const note = await Get(
      `/note/get-note/${location?.pathname.replace("/note/", "")}`
    );

    setNote(note?.note[0]);
  };
   useEffect(() => {
    getNote();
  }, []);
  return (
    note?.content?.length > 0 && (
      <div style={{ height: "100vh", width: "100%", padding: 50 }}>
        <FroalaEdit
       
          type={"share"}
          value={note.content}
          defaultValue={note.content}
        />
      </div>
    )
  );
}
export default SahreNote;
