import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Button, Input, notification, message } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import * as uuid from "uuid";

import CardEditor from "./Components/CardEditor";
import ListEditor from "./Components/ListEditor";
import DashboardContainer from "./DashboardContainer";
import { HashContext } from "../../context/hash/hash.context";
import { Fetch, Get, trimSpaces } from "../../utils";
import { SearchContext } from "../../context/search/search.context";

function Dashboard() {
  const { search } = useContext(SearchContext);
  const { hashId } = useContext(HashContext);
  const { user } = useAuth0();

  const userInfo =localStorage.getItem("user")
  const sub=userInfo && JSON.parse(userInfo).sub

  const [note, setNote] = useState([]);
  const _getNote = async () => {
    const note =
      hashId === "1"
        ? await Get(`/note/${sub}`)
        : await Get(`/note/get/${hashId}`);
    setNote(note?.note);

  };

  useEffect(() => {
    _getNote();
 
  }, [hashId]);

  const _createNewnote = async (type) => {
    const newNote = await Fetch("/note", "post", {
      sub: sub,
      hash: hashId,
      type: type,
    });

    if (newNote?.note) {
      window.location.reload()
      _getNote();
    }
  };
  const _handleremove = async (i, e) => {
    const newUpdate = i.todo.filter((item) => item.id !== e.id);
    await Fetch("/note", "PATCH", {
      id: i._id,
      todo: newUpdate,
    });
    _getNote();
  };
  const toggleTodo = async (i, e) => {
    const newUpdate = i.todo.filter((item) => item._id === e._id);
    const todo = i.todo.filter((item) => item._id !== e._id);
    let array = [...todo];
    const update = {
      ...newUpdate[0],
      completed: !newUpdate[0].completed,
    };
    array.unshift(update);
    await Fetch("/note", "PATCH", {
      id: i._id,
      todo: array,
    });
    _getNote();
  };
  const ondelete = async (id) => {
    await Fetch(`/note/delete`, "post", {
      id: id,
    });
    message.error("Note deleted Successfully");

    _getNote();
  };

  return search.length > 0 ? (
    <DashboardContainer>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Row gutter={[16, 16]}>
            {search !== undefined &&
              search.map((i) => {
                return (
                  i.type === "NOTE" && (
                    <CardEditor
                      onDelete={() => {
                        ondelete(i._id);
                      }}
                      onChange={async (e) => {
                        await Fetch("/note", "PATCH", {
                          id: i._id,
                          content: trimSpaces(e),
                        });
                      }}
                      data={i}
                      title="new bote"
                    />
                  )
                );
              })}
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[16, 16]}>
            {search !== undefined &&
              search.map((i) => {
                return (
                  i.type === "LIST" && (
                    <ListEditor
                      handleRemoveTodo={(e) => {
                        _handleremove(i, e);
                      }}
                      handleToggleTodoStatus={(e) => {
                        toggleTodo(i, e);
                      }}
                      onFormSubmit={async (e) => {
                        let arr = [...i.todo];
                        arr.push({
                          id: uuid.v4(),
                          completed: false,
                          name: e.name,
                        });

                        await Fetch("/note", "PATCH", {
                          id: i._id,
                          todo: arr,
                        });
                        _getNote();
                      }}
                      title="todo"
                      data={i}
                    />
                  )
                );
              })}
          </Row>
        </Col>
      </Row>
    </DashboardContainer>
  ) : (
    <DashboardContainer>
      {hashId !== "1" && (
        <Row style={{ marginBottom: 10, marginTop: 10 }}>
          {/* <Col style={{ marginBottom: 10, marginTop: 10 }} span={24}>
            <Input placeholder="Search" />
          </Col> */}
          <Col span={12}>
            <Button
              onClick={() => {
                _createNewnote("NOTE");
              }}
              className="w-95"
              type="dashed"
            >
              + New Note
            </Button>
          </Col>
          <Col span={12}>
            <Button
              onClick={() => {
                _createNewnote("LIST");
              }}
              style={{ marginLeft: 10 }}
              className="w-95"
              type="dashed"
            >
              + New List
            </Button>
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Row gutter={[16, 16]}>
            {note !== undefined &&
              note.map((i) => {
              
                return (
                  i.type === "NOTE" && (
                    <CardEditor
                      onDelete={() => {
                        ondelete(i._id);
                      }}
                      onChange={async (e) => {
                        await Fetch("/note", "PATCH", {
                          id: i._id,
                          content: trimSpaces(e),
                        });
                      }}
                      data={i}
                      title="new bote"
                    />
                  )
                );
              })}
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[16, 16]}>
            {note !== undefined &&
              note.map((i) => {
                return (
                  i.type === "LIST" && (
                    <ListEditor
                      handleRemoveTodo={(e) => {
                        _handleremove(i, e);
                      }}
                      handleToggleTodoStatus={(e) => {
                        toggleTodo(i, e);
                      }}
                      onFormSubmit={async (e) => {
                        let arr = [...i.todo];
                        arr.push({
                          id: uuid.v4(),
                          completed: false,
                          name: e.name,
                        });

                        await Fetch("/note", "PATCH", {
                          id: i._id,
                          todo: arr,
                        });
                        _getNote();
                      }}
                      title="todo"
                      data={i}
                    />
                  )
                );
              })}
          </Row>
        </Col>
      </Row>
    </DashboardContainer>
  );
}
export default Dashboard;
