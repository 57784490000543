import React, { useContext, useRef, useState } from "react";
import { Tag, Input } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import { PlusOutlined } from "@ant-design/icons";
import { Fetch } from "../../utils";
import { useAuth0 } from "@auth0/auth0-react";
import { HashTopicContext } from "../../containers/Dashboard/DashboardContainer";

const EditableTagGroup = ({ id, tag, type }) => {
  const { _getTopic } = useContext(HashTopicContext);
  const input = useRef(null);
  const { user } = useAuth0();

  let [tags, setTag] = useState(tag);
  const [inputVisible, setinputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const handleClose = async (removedTag) => {
    const tag = tags.filter((tag) => tag !== removedTag);

    await Fetch(`/note/updatetag`, "PATCH", {
      id: id,
      sub: user?.sub,
      tag: tag,
    });
    setTag(tag);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = async () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }

    await Fetch("/note/updatetag", "PATCH", {
      id: id,
      sub: user?.sub,
      tag: tags,
      name: tags[tags.length - 1],
      type: type,
    });
    _getTopic();
    setinputVisible(false);
    setInputValue("");
    setTag(tags);
  };
  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );

    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };
  const tagChild = tags !== undefined && tags.map((i) => forMap(i));
  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <TweenOneGroup
          enter={{
            scale: 0.8,
            opacity: 0,
            type: "from",
            duration: 100,
            onComplete: (e) => {
              e.target.style = "";
            },
          }}
          leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
          appear={false}
        >
          {tagChild}
        </TweenOneGroup>
      </div>
      {inputVisible && (
        <Input
          ref={input}
          type="text"
          size="small"
          style={{ width: 78 }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag
          onClick={() => {
            setinputVisible(true);
          }}
          className="site-tag-plus"
        >
          <PlusOutlined /> New Hash
        </Tag>
      )}
    </>
  );
};
// class EditableTagGroup extends React.Component {
//   state = {
//     tags: ["Tag 1", "Tag 2", "Tag 3"],
//     inputVisible: false,
//     inputValue: "",
//   };

//   render() {
//     const { tags, inputVisible, inputValue } = this.state;
//     const tagChild = tags.map(this.forMap);
//     return (
//       <>
//         <div style={{ marginBottom: 16 }}>
//           <TweenOneGroup
//             enter={{
//               scale: 0.8,
//               opacity: 0,
//               type: "from",
//               duration: 100,
//               onComplete: (e) => {
//                 e.target.style = "";
//               },
//             }}
//             leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
//             appear={false}
//           >
//             {tagChild}
//           </TweenOneGroup>
//         </div>
//         {inputVisible && (
//           <Input
//             ref={this.saveInputRef}
//             type="text"
//             size="small"
//             style={{ width: 78 }}
//             value={inputValue}
//             onChange={this.handleInputChange}
//             onBlur={this.handleInputConfirm}
//             onPressEnter={this.handleInputConfirm}
//           />
//         )}
//         {!inputVisible && (
//           <Tag onClick={this.showInput} className="site-tag-plus">
//             <PlusOutlined /> New Note
//           </Tag>
//         )}
//       </>
//     );
//   }
// }
export default EditableTagGroup;
