import React from "react";
import { Row, Col, Card, PageHeader } from "antd";
import { EditableTagGroup, FroalaEdit } from "../../../components";
import { AddTodoForm } from "../../../components/AddtodoForm";
import { TodoList } from "../../../components/Todo/TodoList";
import "./style.css";
import ContentEditable from "react-contenteditable";
import { Fetch, trimSpaces } from "../../../utils";

const ListEditor = ({
  title,
  data,
  onFormSubmit,
  handleToggleTodoStatus,
  handleRemoveTodo,
}) => {
  const _onupdatetitle = async (id, data) => {
    await Fetch(`/note/updatetitle`, "post", {
      id: id,
      title: data,
    });
  };

  return (
    <>
      <Col span={12}>
        <div className="editor-container">
          <div style={{ width: "257px" }}>
            <ContentEditable
              html={
                data?.title ? trimSpaces(data?.title) : "<p>Type Your Title</p>"
              }
              className="note-title"
              disabled={false}
              onChange={(e) => {
                _onupdatetitle(data._id, e.target.value);
              }}
            />
          </div>
          <Row
            // justify="center"
            // align="middle"
            gutter={[0, 20]}
            // className="todos-container"
          >
            <AddTodoForm onFormSubmit={onFormSubmit} />
            <Col style={{ width: "100%" }}>
              <Card title="Todo List">
                <TodoList
                  todos={data.todo}
                  onTodoRemoval={handleRemoveTodo}
                  onTodoToggle={handleToggleTodoStatus}
                />
              </Card>
            </Col>
          </Row>
          {/* <FroalaEdit type="list" /> */}
          <div className="mt-10">
            <EditableTagGroup type="LIST" id={data._id} tag={data?.tag} />
          </div>
        </div>
      </Col>
    </>
  );
};

export default ListEditor;
