import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import {history} from "./utils";
import { Config } from "./utils/config";

const onRedirectCallback = (appState) => {
  // window.location.replace('/')
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};
ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={Config.domain}
      clientId={Config.clientId}
      redirectUri={window.location.origin}
      audience={Config.audience}
      // onRedirectCallback={onRedirectCallback}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
