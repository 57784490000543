import React, { useState, useEffect, useContext, createContext } from "react";
import { Layout } from "antd";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";

import { Get } from "../../utils";
import { NavBar, SideBar, Loading, TopicMenu } from "../../components";
import { HashContext } from "../../context/hash/hash.context";
import {UserContext} from '../../context/user/user.context'
export const HashTopicContext = createContext({});

function DashboardContainer(props) {
  const { user } = useAuth0();
 
  const { _setHashId } = useContext(HashContext);
  const userInfo =localStorage.getItem("user")
  const sub=userInfo && JSON.parse(userInfo).sub


  const [topics, setTopics] = useState([{ name: "All Notes", id: "1" }]);
  const [contentIndex, setContentIndex] = useState(0);
  const [selectedKey, setSelectedKey] = useState("0");

  const _getTopic = async () => {
    // const SubId = user.sub;
    const topic = await Get(`/hash/${sub}`);
    const newTopic = [{ name: "All Notes", id: "1" }];
    topic?.hash?.map((i) => {
      newTopic.push({ id: i.id, name: i?.name });
    });

    setTopics(newTopic);


    if(localStorage.getItem("current")){
      const key =localStorage.getItem("current")
      setSelectedKey(key);
      setContentIndex(+key);
      _setHashId(newTopic[+key].id);
    }
  };
  useEffect(() => {
    _getTopic();
  }, [user]);

  const changeSelectedKey = (event) => {

    localStorage.setItem("current",event.key)
    const key = event.key;
    setSelectedKey(key);
    setContentIndex(+key);
    _setHashId(topics[+key].id);
  };

  const Menu = (
    <TopicMenu
      topics={topics}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
      _getTopic={_getTopic}
      SubId={userInfo && JSON.parse(userInfo).sub}
    />
  );

  return (
    <HashTopicContext.Provider
      value={{
        _getTopic,
      }}
    >
      <div ref={props.ref} className="App">
        <NavBar menu={Menu} />
        <Layout>
          <SideBar menu={Menu} />
          <Layout.Content
            className="content"
            style={{ height: "92vh", overflow: "auto" }}
          >
            {topics[contentIndex].name}
            {props.children}
          </Layout.Content>
        </Layout>
      </div>
    </HashTopicContext.Provider>
  );
}
export default DashboardContainer