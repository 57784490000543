const DEVELOPMENT = {
  share: "http://localhost:3000/note/",
  server: "http://localhost:3001/v1",
  domain: "hashnotes.us.auth0.com",
  clientId: "Ki11MC0MoKrKPCPbpoTd3i19vIwAb049",
  redirect: "http://localhost:3000",
  audience: "https://hashnotes.us.auth0.com/api/v2/",
};
const PRODUCTION = {
  share: "https://app.infomark.co/note/",
  server: "https://api.infomark.co/v1",
  domain: "hashnotes.us.auth0.com",
  clientId: "Ki11MC0MoKrKPCPbpoTd3i19vIwAb049",
  redirect: "https://app.infomark.co",
  audience: "https://hashnotes.us.auth0.com/api/v2/",

};
export const Config = {
  share: PRODUCTION.share,
  server: PRODUCTION.server,
  domain: PRODUCTION.domain,
  clientId: PRODUCTION.clientId,
  redirect: PRODUCTION.redirect,
  audience: PRODUCTION.audience,
};
