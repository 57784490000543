import React,{useEffect} from "react";
import { Router, Route, Switch } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { history } from "./utils";

import "./App.css";
import Dashboard from "./containers/Dashboard/";
import Loading from "./components/Loading";
import SahreNote from "./containers/shareNote";
import { UserAuthProdvider } from "./context/user/user.provider";
import { HashProdvider } from "./context/hash/hash.provide";
import { SearchProvider } from "./context/search/search.provider";

const App = () => {
  const {
    loginWithRedirect,
    user,
    isLoading,
    error,
    isAuthenticated
  } = useAuth0();
  const userInfo =localStorage.getItem("user")
  const sub=userInfo && JSON.parse(userInfo).sub

  useEffect(() => {
    if (!user && !isLoading &&!sub) {
      loginWithRedirect();
    }
  }, [loginWithRedirect, isLoading, user]);
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }



  return (
    <SearchProvider>
      <HashProdvider>
        <UserAuthProdvider isAuthenticated={isAuthenticated}>
          <Router  history={history}>
            <Switch>
              <Route path="/" exact component={Dashboard} />

              <Route path="/note/:id" exact component={SahreNote} />
            </Switch>
          </Router>
        </UserAuthProdvider>
      </HashProdvider>
    </SearchProvider>
  );
};

export default App;
